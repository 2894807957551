<template>
  <div>
    <div class="error-page">
      <h1 class="h1-404">
        404
      </h1>
      <div class="info">
        <h2>找不到您的路徑</h2>
        <p>曠世國際有限公司</p>
      </div>

      <a
        class="btn primary"
        @click="redirectBack"
      >
        返回上一頁
      </a>
    </div>

    <!-- <div class="copyright">
      COPYRIGHT  © 2012-{{ new Date().getFullYear() }} 曠世國際有限公司
    </div> -->
  </div>
</template>

<script>
export default {
  methods: {
    redirectBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.error-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('~@/assets/images/background/error/404.webp') no-repeat center center fixed;
    background-size: cover;
    content: "";
    left: 0;
    top: 0;
    z-index: -1;
    pointer-events: none;
  }
}
.info {
  color: white;
  text-align: center;
  margin-bottom: 3rem;
  h2 {
    color: white;
    font-size: 2.5rem;
    text-shadow: 5px 5px 2px #9de0f681;
    margin-bottom: 10px;
  }
   p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    text-shadow: 1px 1px #9de0f681;
  }
}

.btn {
  z-index: 1;
  overflow: hidden;
  background: transparent;
  position: relative;
  padding: 8px 50px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 2px;
  transition: 0.2s ease;
  font-weight: bold;
  margin: 5px 0px;
  &.primary {
    border: 3px solid #9de0f6;
    color: #9de0f6;
    text-shadow: 2px 2px 2px #002d3c;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background: #9de0f681;
      z-index: -1;
      transition: 0.2s ease;
    }
    &:hover {
      color: var(--white);
      text-shadow: 2px 2px 2px #06557081;
      background: #9de0f681;
      transition: 0.2s ease;
      &:before {
        width: 100%;
      }
    }
  }
}

.copyright {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px 5px;
  background: #000000;
  text-align: center;
  color: rgb(172, 172, 172);
  font-size: 12px;
  z-index: 350;
}
</style>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&family=Roboto:wght@100;300&display=swap");
.h1-404 {
  font-size: clamp(5rem, 40vmin, 12rem);
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  margin-bottom: 1rem;
  letter-spacing: 1rem;
  transform: translate3d(0, 0, 0vmin);
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: 5px 5px 2px #9de0f681;
}
.h1-404:after {
  content: "404";
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
}
</style>
